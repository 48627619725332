<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center pt-6">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/nav_logo.png')"
              max-height="330px"
              max-width="330px"
              alt="logo"
              contain
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text
          class="fadeOut text-center d-all justify-center mx-auto my-auto"
        >
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center loggedOut">
            Logged Out
            <br>
            <v-btn
              color="success"
              class="mb-4 mt-4 "
              @click="home()"
            >
              Homepage
            </v-btn>
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import {mdiAlert} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      firebase.auth().signOut().then(() => {
        localStorage.removeItem('user')
        console.log('Signed Out')
      }).catch(error => {
        console.log(error)
      })
    },
    home() {
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
